<template>
  <list-layout>
    <template slot="search">
      <b-col cols="4">
        <div class="position-relative">
          <b-form-input v-model="search" placeholder="Search" class="pr-12" @keyup.enter="searchName = search" />
          <font-awesome-icon
            :icon="['fas', 'search']"
            fixed-width
            class="input-search text-primary"
            @click="searchName = search"
          />
        </div>
      </b-col>
      <b-col cols="2">
        <b-form-select v-model="country" :options="countryList">
          <template #first>
            <b-form-select-option :value="null">
              Select Country
            </b-form-select-option>
          </template>
        </b-form-select>
      </b-col>
      <b-col cols="2">
        <b-form-select v-model="facility" :options="facilityList">
          <template #first>
            <b-form-select-option :value="null">
              Select Facility
            </b-form-select-option>
          </template>
        </b-form-select>
      </b-col>
    </template>
    <template slot="actions">
      <b-row>
        <b-col cols="6">
          <b-button variant="secondary" class="btn-action text-primary inline" @click="exportData()">
            <font-awesome-icon :icon="['fas', 'download']" fixed-width />
          </b-button>
        </b-col>
      </b-row>
    </template>
    <template slot="list">
      <b-row>
        <b-col class="d-flex">
          <h4 class="m-2 font-weight-bold text-primary">
            TOTAL CLINIC
          </h4>
          <h4 class="ml-5 m-2 font-weight-bold">
            {{ totalClinic }}
          </h4>
        </b-col>
      </b-row>
      <hr>
      <div class="table-base">
        <b-table
          hover
          borderless
          :items="sortClinicList"
          :current-page="currentPage"
          :per-page="rowPrePage"
          :fields="fields"
          @row-clicked="onSelected"
        >
          <template #head(name)>
            <div class="cursor-pointer" @click="sortList('name')">
              NAME
              <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
            </div>
          </template>
          <template #head(status)>
            <div class="cursor-pointer" @click="sortList('status')">
              STATUS
              <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
            </div>
          </template>
          <template #head(location)>
            <div class="cursor-pointer" @click="sortList('location')">
              LOCATION
              <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
            </div>
          </template>
          <template #head(facility)>
            <div class="cursor-pointer" @click="sortList('facility')">
              FACILITY
              <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
            </div>
          </template>
          <template #head(product_count)>
            <div class="cursor-pointer" @click="sortList('type')">
              PRODUCY QTY
              <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
            </div>
          </template>

          <template #cell(name)="data">
            {{ data.item.name }}
          </template>
          <template #cell(facility)="data">
            {{ getFacilityText(data.item.facility,data.item.facility_other) }}
          </template>
          <template #cell(status)="data">
            <span v-if="data.item.status === 0" class="d-inline-block bg-primary text-white rounded-pill px-2 py-0">
              Pending
            </span>
            <span v-else class="d-inline-block bg-success text-white rounded-pill px-2 py-0">
              Active
            </span>
          </template>
        </b-table>
        <div class="d-flex justify-content-between">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="rowPrePage"
            align="fill"
            size="sm"
            class="my-0"
            first-number
            last-number
          />
          <row-count :total-rows="totalRows" :row-pre-page="rowPrePage" :current-page="currentPage" />
        </div>
      </div>
    </template>
  </list-layout>
</template>

<script>
import listLayout from '@/components/layouts/partner/listLayout'
import countryList from '@/common/countryList'
import facilityList from '@/common/facilityList'
import { getClinicList } from '@/api/clinic'
import RowCount from '@/components/common/rowCount'

export default {
  name: 'ClinicList',
  components: {
    listLayout,
    RowCount
  },
  data () {
    return {
      search: '',

      searchName: '',
      country: null,
      facility: null,
      field: 'name',
      ascending: true,

      clinicList: [],
      currentPage: 1,
      rowPrePage: 6
    }
  },
  computed: {
    countryList () {
      return countryList
    },
    facilityList () {
      return facilityList
    },
    fields () {
      return ['name', 'location', 'product_count', 'facility', 'status']
    },
    filterClinicList () {
      return this.clinicList.reduce((list, clinic) => {
        if (this.searchName && clinic.corporate_name.indexOf(this.searchName) === -1) return list
        if (this.country && clinic.corporate_country !== this.country) return list
        if (this.facility !== null && clinic.facility !== this.facility) return list

        const country = this.countryList.find(c => c.value === clinic.clinic_country)?.text
        list.push({
          id: clinic.id,
          name: clinic.clinic_name,
          status: clinic.status,
          location: `${clinic.clinic_city}, ${country}`,
          facility: clinic.facility,
          facility_other: clinic.facility_other,
          product_count: clinic.product_count,
          promoter: clinic.promoter
        })
        return list
      }, [])
    },
    sortClinicList () {
      return this.filterClinicList.slice().sort((a, b) => {
        const dataA = String(a[this.field]).toLowerCase()
        const dataB = String(b[this.field]).toLowerCase()
        if (dataA > dataB) return this.ascending ? 1 : -1
        if (dataA < dataB) return this.ascending ? -1 : 1
        else return 0
      })
    },
    totalRows () {
      return this.sortClinicList.length
    },
    totalClinic () {
      return this.clinicList.length
    }
  },
  created () {
    this.getClinicList()
  },
  methods: {
    getClinicList () {
      getClinicList().then(res => {
        this.clinicList = res.data.data
      })
    },
    sortList (field) {
      if (this.field === field) {
        this.ascending = !this.ascending
      } else {
        this.field = field
        this.ascending = true
      }
    },
    onSelected (item) {
      this.$router.push({ name: 'ClinicDetail', params: { id: item.id, partnerId: item.promoter } })
    },
    getFacilityText (item, other) {
      if (item === 99) return other
      else return this.facilityList.find(x => x.value === item).text
    }
  }
}
</script>
